<template>
	<div>

		<div class="border border-solid border-blue-200">
			<datatable
				:data="loans.data"
				:columns="loans.columns"
				:query="searchQuery"
				:loading="loans.loading"
				:onClicks="click"
				v-model="selection"
				:selectable="true"
				:exportable="true"
				ref="table"
			/>
		</div>

		<div class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg">
			<div class="text-sm text-gray-700 mb-4">{{ selection.length }} Loan{{ selection.length !== 1 ? 's' : '' }} Selected!</div>

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-4">
					<span class="alert-icon">!</span>
					<span>{{ getFormError(form) }}</span>
				</div>
			</template>

			<div>
				<button class="btn btn-blue btn-sm mr-3" @click.prevent="confirm" :disabled="form.loading || !selection.length">
					<ion-icon name="checkmark-done-outline" class="text-lg mr-2"></ion-icon>
					<span v-if="form.loading">Moving</span>
					<span v-else>Move to Pending</span>
				</button>
				<button class="btn btn-gray btn-sm" @click.prevent="clear" :disabled="form.loading || !selection.length">
					<ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
					<span>Clear Selection</span>
				</button>
			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10" ref="viewModal">
						
			<template v-if="selectedLoan">
				<h1 class="font-bold text-xl mb-10">Loan Details</h1>
				<div class="text-sm text-gray-700">
				</div>
			</template>
			<template v-else>
				<h1 class="font-bold text-xl mb-10">No Loan Selected!</h1>
				<div class="text-sm text-gray-700">
					Please select a loan.
				</div>
			</template>

			<div class="text-center">
				<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.viewModal.close">
					Close
				</button>
			</div>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Loans have been moved to pending.
			</div>
			<div class="text-sm mb-10">
				You can mark them as disbursed again by clicking the pending tab.
			</div>

			<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.successModal.close">
				Okay
			</button>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="confirmModal">

			<img :src="exclamation" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Please be sure.
			</div>
			<div class="text-sm font-normal mb-10">
				This action will clear all repayments attached to these loans.
				Please make sure the users didn't already receive payment.
			</div>

			<div class="flex items-center justify-center">
				<button type="button" class="btn btn-blue btn-md mr-2" @click.prevent="undisburse">
					I'm sure.
				</button>
				<button type="button" class="btn btn-orange btn-md" @click.prevent="$refs.confirmModal.close">
					I'm not sure.
				</button>
			</div>
			
		</modal>

	</div>
</template>

<script>
	export default {
		props: {
			searchQuery: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				selectedLoan: null,
				selection: [],
				checkmark: require('@/assets/checkmark-base.svg'),
				exclamation: require('@/assets/exclamation.svg'),
				loans: this.$options.resource([], {
					columns: [
						{
							name: 'id',
							th: 'Loan ID'
						},
						{
							name: 'name',
							th: 'Staff Name',
							render: (loan) => {
								if(!loan.user) {
									return 'N/A';
								}
								return `${loan.user.name} ${loan.user.last_name}`
							}
						},
						{
							name: 'company',
							th: 'Company',
							render: (loan) => loan.user.company?.name
						},
						{
							name: 'loan_amount',
							th: 'Disbursed Amount',
							render: (loan) => `₦ ${this.$options.filters.currency(loan.loan_amount)}`
						},
						{
							name: 'requested_amount',
							th: 'Requested Amount',
							render: (loan) => `₦ ${this.$options.filters.currency(loan?.requested_amount)}`,
						},
						{
							name: 'email',
							th: 'Bank Account',
							render: (loan) => loan.user.profile?.account_no
						},
						{
							name: 'phone',
							th: 'Bank Name',
							render: (loan) => this.$options.filters.bankName(loan.user.profile?.bank_name, this.banks)
						},
						{
							name: 'disbursed_at',
							th: 'Disbursement Date',
							render: (loan, date) => date
						},
					],
				}),
				form: this.$options.basicForm([]),
			}
		},
		computed: {
			banks() {
				return this.$store.state.banks;
			}
		},
		beforeMount() {
			this.getLoans();
		},
		mounted() {
			this.$options.listenForClick.apply(this);
		},
		methods: {
			click(loan) {
				this.selectedLoan = loan;
				this.$refs.viewModal.open();
			},
			clear() {
				this.$refs.table.clearSelection();
			},
			confirm() {
				this.$refs.confirmModal.open();
			},
			async getLoans() {
				this.loans.loading = true;
				await this.sendRequest('admin.companies.loans.disbursed', {
					success: response => {
						this.loans.data = response.data.loans;
						if (this.selectedLoan) {
							this.selectedLoan = this.loans.data.find( loan => loan.id === this.selectedLoan.id );
						}
					}
				});
				this.loans.loading = false;
			},
			async undisburse() {
				this.$refs.confirmModal.close();

				this.form.loading = true;
				this.form.error = null;
				await this.sendRequest('admin.companies.loans.undisburse_many', {
					data: {
						loans: this.selection.map( item => item.row.id )
					},
					success: () => {
						this.$refs.successModal.open();
						this.clear();
						this.getLoans();
					},
					error: error => {
						this.form.error = error;
					}
				});
				this.form.loading = false;
			},
		}
	}
</script>