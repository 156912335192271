<template>
	<div>

		<div class="border border-solid border-blue-200">
			<datatable
				:data="loans.data"
				:columns="loans.columns"
				:query="searchQuery"
				:loading="loans.loading"
				:onClicks="click"
				v-model="selection"
				:selectable="true"
				:exportable="true"
				ref="table"
			/>
		</div>

		<div class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg">
			<div class="text-sm text-gray-700 mb-4">{{ selection.length }} Loan{{ selection.length !== 1 ? 's' : '' }} Selected!</div>

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-4">
					<span class="alert-icon">!</span>
					<span>{{ getFormError(form) }}</span>
				</div>
			</template>

			<div class="mb-10 max-h-200px overflow-y-auto">
				<template v-for="(failure, n) in loans.failures">
					<div :class="{ 'border-b border-blue-200 pb-1 mb-2': n < loans.failures.length - 1 }" :key="n">
						<div class="flex items-center text-xs">
							<span class="mr-3">Loan ID: {{ failure.loan.id }}</span>
							<span >Reason: {{ failure.reason }}</span>
						</div>
					</div>
				</template>
			</div>

			<form-group
				type="select"
				:options="lenderOptions"
				left-icon="business-outline"
				name="lender"
				:form="form"
				:loading="lenders.loading"
				v-model="form.data.lender.value"
			>
				Select Lender
			</form-group>

			<div>
				<button class="btn btn-blue btn-sm mr-3" @click.prevent="disburse" :disabled="form.loading || !selection.length || !form.data.lender.value">
					<ion-icon name="checkmark-done-outline" class="text-lg mr-2"></ion-icon>
					<span v-if="form.loading">Marking</span>
					<span v-else>Mark As Disbursed</span>
				</button>
				<button class="btn btn-gray btn-sm" @click.prevent="clear" :disabled="form.loading || !selection.length">
					<ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
					<span>Clear Selection</span>
				</button>
			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10" ref="viewModal">
						
			<template v-if="selectedLoan">
				<h1 class="font-bold text-xl mb-10">Loan Details</h1>
				<div class="text-sm text-gray-700">
				</div>
			</template>
			<template v-else>
				<h1 class="font-bold text-xl mb-10">No Loan Selected!</h1>
				<div class="text-sm text-gray-700">
					Please select a loan.
				</div>
			</template>

			<div class="text-center">
				<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.viewModal.close">
					Close
				</button>
			</div>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Loans successfully marked as disbursed!
			</div>
			<div class="text-sm mb-10">
				The user will be notified that his bank account has been credited.
			</div>

			<button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.successModal.close">
				Okay
			</button>
			
		</modal>

	</div>
</template>

<script>
	export default {
		props: {
			searchQuery: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				selectedLoan: null,
				selection: [],
				checkmark: require('@/assets/checkmark-base.svg'),
				lenders: this.$options.resource([]),
				loans: this.$options.resource([], {
					columns: [
						{
							name: 'id',
							th: 'Loan ID'
						},
						{
							name: 'name',
							th: 'User Name',
							render: (loan) => `${loan.user?.name} ${loan.user?.last_name || ''}`
						},
						{
							name: 'company',
							th: 'Company',
							render: (loan) => loan.user.company?.name
						},
						{
							name: 'loan_amount',
							th: 'Loan Amount',
							render: (loan) => `₦ ${this.$options.filters.currency(loan.loan_amount)}`
						},
						{
							name: 'email',
							th: 'Bank Account',
							render: (loan) => loan.user.profile?.account_no
						},
						{
							name: 'phone',
							th: 'Bank Name',
							render: (loan) => this.$options.filters.bankName(loan.user.profile?.bank_name, this.banks)
						},
					],
					successes: [],
					failures: []
				}),
				form: this.$options.basicForm(['lender']),
			}
		},
		computed: {
			banks() {
				return this.$store.state.banks;
			},
			lenderOptions() {
				return this.lenders.data?.map(lender => ({ title: lender.name, value: lender.id }));
			}
		},
		beforeMount() {
			this.getLoans();
			this.getLenders();
		},
		mounted() {
			this.$options.listenForClick.apply(this);
		},
		methods: {
			click(loan) {
				this.selectedLoan = loan;
				this.$refs.viewModal.open();
			},
			clear() {
				this.$refs.table.clearSelection();
			},
			async getLoans() {
				this.loans.loading = true;
				await this.sendRequest('admin.companies.loans.approved', {
					success: response => {
						this.loans.data = response.data.loans;
						if (this.selectedLoan) {
							this.selectedLoan = this.loans.data.find( loan => loan.id === this.selectedLoan.id );
						}
					}
				});
				this.loans.loading = false;
			},
			async getLenders() {
				this.lenders.loading = true;
				await this.sendRequest('admin.lenders.all', {
					success: response => {
						this.lenders.data = response.data.lenders;
					}
				});
				this.lenders.loading = false;
			},
			async disburse() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.loans.successes = [];
				this.loans.failures = [];

				this.form.loading = true;
				this.form.error = null;
				await this.sendRequest('admin.companies.loans.disburse_many', {
					data: {
						loans: this.selection.map( item => item.row.id ),
						lender: this.form.data.lender.value
					},
					success: (response) => {
						const successes = response.data?.successes;
						const failures = response.data?.failures;

						this.loans.successes = successes;
						this.loans.failures = failures;

						this.$refs.successModal.open();

						if (this.loans.successes.length) {
							this.clear();
							this.getLoans();
						}
					},
					error: error => {
						this.form.error = error;

						if (error?.response?.data?.failures) {
							this.loans.failures = error?.response?.data?.failures;
						}
					}
				});
				this.form.loading = false;
			},
		}
	}
</script>